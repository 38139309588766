.captcha-container {
    text-align: center;
    h4{
        color:#000 !important;
        text-align:center !important;
    }
    .capctha-image {
        display: flex;
        justify-content: center;
        height: 60px;
        img {
            height: 100%;
            margin-right: -90px;
        }       
    }    
    .captcha-error {
        color: #dc3545;
        text-align: center;
        font-size: 14px;
    }      
    .captcha-action-container {
        display: flex;
        grid-gap: 16px; 
        margin-top: 16px;
        margin-bottom: 16px; 
        justify-content: center;
        input {
            border: 1px solid;
            font-size: 16px;
        }
        button {
            height: 40px;
            width: 120px;
            border: 1px solid;
        }
    }
}