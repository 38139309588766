@import './_breakpoints.scss';
@import './_font.scss';
main {
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-family: 'Archer';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;
    @include tablet-down {
        font-size: 14px;
    }
}
p, h1, h2, h2, h4, h5 {
    margin: 0;
  }