@import '../../assets/styles/breakpoints';
.socials-container{
  display: flex;
  width: 100%;
  justify-content: center;
  @include tablet-up {
    justify-content: start;
  }  
  .socials {
    display: flex;
    justify-content: space-between;
    max-width: calc(min($screen-mobile, 100vw) - 40px);
    width: 100%;
    @include tablet-only {
      max-width:none;
      width: 400px;
    }

    &.dark {
      .social {
        svg path{
          fill: black;
        }
      }
    }
    &.light {
      .social {
        svg path{
          fill: white;
        }
      }
    }
    &.alt{
      .social{
        svg path{
          fill: #363B3B;
        }
      }
    }
    .social {
      width: 32px;
      display: flex;
      justify-content: center;
      svg {
        width: 28px;
        height: 28px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
