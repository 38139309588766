@import '../../assets/styles/breakpoints';
.button {
    border: none;
    height: 60px;
    width:100%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.35px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;    
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    @include tablet-up {
        width: 220px;
    }
    &.dark {        
        background-color: black;
        color: white;
        &:hover {
            background-color: white;
            color: black;
        }
    }
    &.light {
        background-color: white;
        color: black;
        &:hover {
            background-color: black;
            color: white;
        }
    }    
    &.alt{
        background-color:#363B3B;
        color:#fff;
        &:hover{
            background-color:#000;
            color:#fff;
        }
    }
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
        &:hover {
            background-color: initial;
            color: initial;
        }
    }
    
}