@import "../../assets/styles/breakpoints";

#banner{
    width: 100%;
    max-width:1346px;
    margin-left:auto;
    margin-right:auto;
    height: fit-content;
    display: flex;
    justify-content: start;
    align-items: center;
    background: transparent url("../../assets/images/s25/s25-collection-banner-mobile.jpg") right/100% auto no-repeat;
    height:calc(100vw * (170 / 414));
    // padding-top:calc(((170 / 414) * 100vw - 170px) / 2);
    // padding-bottom:calc(((170 / 414) * 100vw - 170px) / 2);
    // @include tablet-only{
    //     height: fit-content;
    // }
    @media screen and (max-width: 370px){
        height: 150px;
        background-position:left;
    }
    @include tablet-up {
        background-image: url("../../assets/images/s25/s25-collection-banner-desktop.jpg");
        height: calc(100vw * (222 / 768));
    }
    @include desktop-lg-up{
        height:298px;
        background-size: 100%;
    }
    @media screen and (min-width: 1455px){  
        background-position-x: 60%;
    }
    .banner-text-container{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-left: 5%;
        min-height: inherit;
        width: 33%;
        align-items: flex-start;
        justify-content: center;
        @media (min-width: 1020px) {
            margin-left:calc(50vw - 485px);
            padding:0;
        }
        @media(min-width: 1344px){
            margin-left:200px;
        }

        .banner-lockup{
            min-height:70px;
            width:200px;
            @media (min-width: 1020px) {
                width:calc(100 * 225 / 1344);
                height:auto;
            }
            @media(min-width: 1344px){
                width: 225px;
                height:auto;
            }
        }
    }
    h3{
        color:#fff;
        font-family:'Playfair Display';
        font-weight:600;
        font-size:6vw;
        line-height:1.2;
        margin:0;
        @include tablet-up{
            font-size:3.5vw;
        }
        @include desktop-lg-up{
            font-size:48px;
        }
        span{
            font-family: "Montserrat";
            font-weight:700;
            text-transform:uppercase;
            display:block;
            font-size: 3vw;
            @include tablet-up{
                font-size: 1.5vw;
            }
            @include desktop-lg-up{
                font-size:18px;
            }
        }
    }
    .banner-button{
        position: relative;
        text-decoration: none;
        border-bottom: 2px white solid;
        color: white;
        font-family: "Montserrat";
        font-size: 12px;
        width: fit-content;
    }
}