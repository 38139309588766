@import "../../assets/styles/breakpoints";
#virtualscoop {
  min-height: 100vh;
  background-image: url("../../assets/images/f23/F23-fsb-watch-bg.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .logo{
    position: relative;
    bottom:-10px;
  }
  .container {
    max-width: $screen-desktop-lg;
    margin: 0 auto;
    padding: 48px 20px;
    @include tablet-down {
      padding: 20px 14px;
    }
    @include tablet-up {
      padding: 60px 32px;
    }
    .page-title {
      margin: 0 auto;
      display: flex;
      flex-direction:row;
      grid-gap: 45px;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      @include tablet-down {
        flex-direction:column-reverse;
        grid-gap: 30px;
      }
      @include tablet-up {
        grid-gap: 60px;
      }
      .title-text-container {
        font-family:'Didot Pro Bold';
        color:white;
        text-transform:uppercase;
        text-align:center;
        font-size: 8.9vw;
        line-height:1;
        position:relative;
        span{
            text-transform:none;
            font-family: 'Montserrat';
            font-weight:300;
            font-size:5vw;
            line-height:36px;
            @include mobile-up{
                font-size:3.5vw;
            }
            @include tablet-up {
                font-size:34px;
                line-height:42px;
            }
        }
        @include mobile-up{
            font-size: 6vw;
        }
        @media (min-width: 768px){
            text-align:left;
        }
        @include tablet-up {
            text-align:left;
            font-size:50px;
            line-height:52px;
            letter-spacing:0.09px;
        }
      }
    }
    .socials {
      margin-top: 48px;
      margin: 48px auto 0 auto;
      @include tablet-down {
        margin-top: 27px;
      }
    }
    .watch-container {
      display: grid;
      grid-template-columns: 1fr;
      @include tablet-up {
        grid-template-columns: 80% 1fr;
      }
      @include desktop-lg-up{
        grid-template-columns: 920px 1fr;
      }
      .video {
        border: 4px solid #ffffff;
        background-color: white;
        height: 0;
        position: relative;
        padding-bottom: 56.5%;
        @media (min-width:1000px) and (max-width:1200px){
          // padding-bottom:calc(.565 * 80vw);
          // width:
        }
        iframe {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      margin-top: 32px;
      @include tablet-down {
        margin-top: 28px;
      }
      @include tablet-up {
        margin-top: 48px;
      }
      .video-items {
        display: flex;
        flex-direction: row;
        width: 100%;
        @include tablet-down {
          flex-direction: column;
        }
        @include tablet-up {
          flex-direction: column;
        }
        .item {
          color: #000000;
          cursor: pointer;
          opacity: 1;
          background-color: #DFC0D5;
          border: 2px solid #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px;
          text-decoration: none;
          width: 25%;
          min-height: 158px;    
          box-sizing:border-box;    
          @include tablet-down {
            width: unset;
            min-height: unset;
            justify-content: flex-start;
          } 
          @include tablet-up {            
            height: 25%;
            width: 100%;
            min-height: unset;
          }
          &:hover {
            opacity: 1;
          }
          &.active {
            background: white;
            opacity: 1;
            width: 50%; 
            @include tablet-down {
              width: unset;
            }  
            @include tablet-up {            
              height: 50%;
              width: 100%;
            }
          } 
          .title {
            font-family: "Montserrat";
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.73px;
            text-decoration: none;
            margin-top: 0;
            margin-bottom: 0;
            font-style: normal;
            font-weight: 600;
          }
          .description {
            font-family: "Archer";
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.73px;
            font-style: italic;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }
  }
}
