@import '../../assets/styles/breakpoints';
.bottom-logo {
    position: absolute;
    right: 5%;
    bottom: 5%;
    @include desktop-wide-up {
        right: 10%;
    }
    svg {
      width: 106px;
      height: 108px;
      @include tablet-only{
        width:74px;
        height:75px;
      }
    }
    &.dark {
      svg {
        fill: black;
      }
    }
    &.light {
        svg {
          fill: white;
        }
      }
    &.alt{
      svg{
        fill: #363B3B;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
  