@import '../../assets/styles/breakpoints';
#coming-soon-page {
    width: 100vw;     
    min-height: 100vh;
    position: relative;
    img{
        max-width:100%;
    }
    @include mobile-only {
        background: #3D1305 url("../../assets/images/s25/S25-coming-soon-bg-mobile.png") top center/100% auto no-repeat;
        // &::before {
        //     position: absolute;
        //     top: 0;
        //     content: ' ';
        //     left: 0;
        //     width: 100vw;
        //     height: 368px;
        //     background: url("../../assets/images/f24/F24-fsb-coming-soon-bg-mobile.jpg") top center/cover no-repeat;
        // }
    }
    @include mobile-up{
        background: url("../../assets/images/s25/S25-coming-soon-bg-desktop.png") 20% center/cover no-repeat;
        // &:before{
        //     background: url("../../assets/images/f24/F24-fsb-coming-soon-bg.jpg") 100% 0%/1300px no-repeat;
        // }
    }
    @include tablet-up {
        background: url("../../assets/images/s25/S25-coming-soon-bg-desktop.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x:25%;
    }
    @include desktop-up{
        background-position-x: 40%;
    }
    @include desktop-lg-up {
        // background: url("../../assets/images/f24/F24-fsb-coming-soon-bg.jpg");
        background-size: cover;
        background-position-x: 30%;
    }
    @include desktop-wide-up {
        // background:#F1F2ED url("../../assets/images/f24/F24-fsb-coming-soon-bg.jpg");
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
    } 
    // .video-container{
    //     padding: 0;
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    //     position: absolute;
    //     top:0;
    //     left:0;
    //     z-index:1;
    //     // filter:blur(10vw) hue-rotate(90deg) brightness(0.4);;
    //     iframe{
    //         box-sizing: border-box;
    //         width: 75vh;
    //         height: 177.77777778vw;
    //         min-width: 100%;
    //         min-height: 100%;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%);
    //         @include tablet-up {
    //             width: calc(133.333333vh * 1.5);
    //             height: 75vw;
    //         }
    //     }
    // }
    .title-logo-container{
        display: flex;
        justify-content: center;
        // @include tablet-only {
        // display: flex;
        // justify-content: start;
        // }
        
        @include tablet-up {
        display: flex;
        justify-content: start;
        }
        .title-logo{
            width: 300px;
            @include mobile-up {
            width: 400px;
            }
        }
    }
    .container {
        padding: 104px 14px 54px 14px;
        position: relative;
        min-height: calc(100vh - 158px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // max-width: 2200px;
        margin: 0 auto;
   
        // @include mobile-up {
        //     padding: 54px 18px 54px 18px;  
        // }
        // @include tablet-only {
        //     padding: 54px 32px 54px 32px;  
        // }
        @include tablet-up {
            padding: 38px 32px 14px 0;  
            min-height: calc(100vh - 52px);
        }
        @include desktop-lg-up {
            padding: 38px 80px 14px 80px;  
        }  
        // @include desktop-wide-up {
        //     padding-left: 120px;
        // }
        .page-content {
            max-width: 570px;
            margin:auto;
            position:relative;
            z-index:2;
            text-align: center;
            width:100%;
            @include mobile-up{
                margin: auto auto auto 0;
            }
            @include tablet-up{
                max-width:650px;
            }
            .contain-tablet-down {
                @include tablet-down {
                    max-width: 60%;
                }
                @include tablet-down 
                {
                    padding: 0 12px;
                    margin-top: 10px;
                    max-width: 100%;
                }
            }
            form {            
                margin-top: 20px;
                margin-bottom: 60px;
                display:flex;
                flex-flow:column nowrap;
                width:440px;
                max-width:100%;
                align-items:center;
                margin:auto;
                .input-box-container{
                    width:100%;
                    .input-label{
                        text-align:left;
                    }
                }
                .button.light{
                    background:#E8CFA8;
                    &:hover{
                        background:#fff;
                        color:#000;
                    }
                }
                @include tablet-down {
                    margin-bottom: 20px;
                }
            }
            a {
                color: unset;
            }
        }
    }    
    h1{
       text-align: center; 
       font-size: 17px;
       margin: calc(90vw * 328 / 430) 0 0 0 ;
       height: fit-content;
       font-family: Montserrat;
       color:#fff;
       display: block;
       width:100%;
       text-align:center;
       line-height:1.2;
       font-weight:300;
       letter-spacing:9px;
       @include mobile-up{
            margin-top:70px;
       }
        @include tablet-up {
            line-height:1.2;
            color:#FFF;
            background:none;
            position:static;
            transform:none;
            padding:0;
            max-width:none;
            font-size:42px;
            margin-top:70px;
        }
    }
    h2{
        font-family: "Playfair Display", serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        text-align:center;
        font-size:44px;
        line-height:1.2;
        margin:30px auto;
        color:#FFF;

        span{
            text-transform:lowercase;
        }
        @media (max-width: 385px) {
            font-size:32px;
        }  
        @include tablet-up{
            font-size:70px;
        }
    }
    h3{
        text-align: center;
        color: #FFF;
        margin: 0 0 22px 0;
        font-size: 3.3vw;
        line-height: 1.2;
        font-family: Montserrat;
        letter-spacing:.6px;
        // text-transform:uppercase;
        font-weight:300;
        strong{
            display:inline-block;
            margin-bottom:10px;
        }
        @include mobile-up{
            font-size:15px;
        }
        @include tablet-up {
            margin: 0 0 0 0;
            margin: 10px 0 30px 0;
            font-size:18px;
            line-height: 1.2;
        }
    }
    h4{
        color: #FFF;
        text-align: center;
        max-width:340px;
        margin:auto;
        span{
            display:block;
            margin-bottom:15px;
        }
        &:last-of-type{
            margin-bottom:40px;
            font-style:italic;
        }
    }

    .captcha-container{
        h4{
            color:#000;
        }
    }

    .cabiLogoContainer{
        display: flex;
        justify-content: center;
        z-index: 1;
        text-align:center;
        position:absolute;
        top:calc((-85 / 348) * 25vh);
        left:50%;
        transform:translate(-50%, -50%);
        @media screen and (max-width: 325px){
            top:calc(-8vh);
        }
        @include mobile-up{
            top:calc((-65 / 348) * 25vh);
        }
        @include tablet-up{
            position:relative;
            left:auto;
            transform:none;
            top:0;
        }
        svg{
            max-width:90%;
            width:15vw;
        }
    }
    .register-success{
        margin-top:30px;
  
    }
    .input-box-container{
        margin-bottom:15px;
    }
}