
@font-face {
  font-family:'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.eot');
src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.eot?#iefix') format('embedded-opentype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.woff2') format('woff2'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.woff') format('woff'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.ttf') format('truetype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.otf') format('opentype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Light.svg#Montserrat Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}
@font-face {
  font-family:'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.eot');
src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.eot?#iefix') format('embedded-opentype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.woff2') format('woff2'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.woff') format('woff'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.ttf') format('truetype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.otf') format('opentype'),
  url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat Regular.svg#Montserrat Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB02;
}
@font-face {
  font-family: 'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.eot');
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.woff2') format('woff2'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.woff') format('woff'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.ttf') format('truetype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.eot');
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.eot');
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.woff') format('woff'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.ttf') format('truetype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.eot');
  src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.woff2') format('woff2'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.woff') format('woff'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.ttf') format('truetype'),
      url('//media-fall.cabionline.com/wp-content/uploads/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family:'Archer';
    src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.eot');
	src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.eot?#iefix') format('embedded-opentype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.woff2') format('woff2'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.woff') format('woff'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.ttf') format('truetype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.otf') format('opentype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-Book.svg#Archer-Book') format('svg');
    font-weight: 325;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}
@font-face {
    font-family:'Archer';
    src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.eot');
	src: url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.eot?#iefix') format('embedded-opentype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.woff2') format('woff2'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.woff') format('woff'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.ttf') format('truetype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.otf') format('opentype'),
		url('//media-fall.cabionline.com/wp-content/uploads/fonts/Archer-BookItalic.svg#Archer-BookItalic') format('svg');
    font-weight: 325;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

//fall 2022 fonts
@font-face {
  font-family: "Satisfy";
  src: url("//media-fall.cabionline.com/wp-content/uploads/fonts/Satisfy.ttf");
  src: url("//media-fall.cabionline.com/wp-content/uploads/fonts/Satisfy.woff2") format("woff2");
  src: url("//media-fall.cabionline.com/wp-content/uploads/fonts/Satisfy.woff") format("woff"),
    url("//media-fall.cabionline.com/wp-content/uploads/fonts/Satisfy.otf") format("opentype");
  font-weight: normal;
}

.playfair-bold {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

// @font-face {
//   font-family: "Didot Pro Bold";
//   src: url("//media-fall.cabionline.com/wp-content/uploads/fonts/LinotypeDidotProBold.woff2") format("woff2");
//   src: url("//media-fall.cabionline.com/wp-content/uploads/fonts/LinotypeDidotProBold.woff") format("woff");
//   font-weight: bold;
// }
