@import '../../assets/styles/breakpoints';
.typography-h1 {
  font-family: "Montserrat";
  font-size: 22px;
  line-height: 50px;
  font-weight: 400;
  color: #FFD284;
  text-align: center;
  @include tablet-only {
    font-size: 64px;
    line-height: 67px;
  }
  @include tablet-up {
    font-size: 91px;
    line-height: 80px;
  }
}
.typography-h2 {
  font-family: Montserrat;
  font-size: 24px;
  line-height: 33px;
  font-weight: 300;
  @include tablet-only {
    font-size: 30px;
    line-height: 34px;
  }
  @include tablet-up {
    font-size: 40px;
    line-height: 42px;
  }
}

.typography-h3 {
  font-family: "Din Condensed bold";
  color: white;
  font-size: 20px;
  line-height: 19px;
  font-weight: 400;
  @include tablet-only {
    font-size: 20px;
    line-height: 19px;
  }
  @include tablet-up {
    font-size: 22px;
    line-height: 24px;
  }
}
.typography-h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  @include tablet-up{
    line-height:24px;
  }
}
.typography-p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.07px;
}
