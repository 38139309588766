@import "/src/assets/styles/breakpoints";

.modal-container {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.45);
    overflow:hidden;
    .modal-wrapper {
        position: relative;        
        width: 90%;
        max-height: 80vh;
        animation: fadeIn 1.5s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
        background: white;    
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); 
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
        max-width: 480px;
        .modal-close {
            position:absolute;
            top: 4px;
            right: 4px;
            svg {
                width: 24px;
                height: 24px;
                &:hover {
                    transform: scale(1.01);
                    cursor: pointer;
                }
            }
        }
        .modal-content {                         
            overflow: auto;
            padding: 16px;
            @include tablet-down {
                padding: 8px;
            }
        }
    }
}