.input-box-container {
  &:nth-last-child(3){
    display:none;
  }
  .input-box {
    background-color: white;
    padding: 8px 14px 12px 14px;
    margin-bottom: 5px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    .input-label {
      color: #6D6D6D;
      font-family: Montserrat;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
    }
    input {
      background-color: transparent;
      border: none !important;
      height: 100%;
      width: 100%;
      font-family: Archer;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 26px;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
  .input-error-container {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    margin-top: -5px;
    padding: 10px 14px;
    font-family: Archer;
    font-style: italic;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    background-color: #F9B795;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
