@import '../../assets/styles/breakpoints';
#dayofshow-page {
    width: 100vw;     
    min-height: 100vh;
    position: relative;
    @include tablet-down {
        background: #341A0B url("../../assets/images/s25/S25-fsb-day-of-bg-mobile.jpg") top center/cover no-repeat;
    }
    @include mobile-only{
        background-size:120%;
    }
    @include tablet-up {
        background: url("../../assets/images/s25/S25-fsb-day-of-bg.jpg") 60% top/cover no-repeat;
    }
    @include desktop-lg-up {
        background-position-x: 30%;
    }
    @include desktop-wide-up {
        background-position-x: right;
        background-size: cover;
    } 
    // .title-logo-container{
    //     display: flex;
    //     justify-content: center;
    //     @include tablet-only {
    //     display: flex;
    //     justify-content: start;
    //     }
        
    //     @include tablet-up {
    //     display: flex;
    //     justify-content: start;
    //     }
    //     .title-logo{
    //         width: 300px;
    //         @include mobile-up {
    //         width: 400px;
    //         }
    //     }
    // }
    .container {
        padding: 0px 14px 54px 14px;
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 2200px;
        margin: 0 auto;
        box-sizing: border-box;
        @media (min-width: 640px) {
            padding: 54px 18px 54px 18px;  
        }
        @include tablet-up {
            padding: 60px 32px 54px 32px;  
        }
        @include desktop-lg-up {
            padding: 78px 80px 54px 80px;  
        }  
        @include desktop-wide-up {
            padding-left: 120px;
        }
        .page-content {
            max-width: $screen-mobile;
            position:relative;
            margin:300px auto 0;
            @media (max-width:375px){
                margin-top:calc(200 / 300 * 100vw);
            }
            @include tablet-up{
                margin:0;
                max-width:40%;
                width: 450px;
            }
            .contain-tablet-down {
                @media (max-width: 640px) {
                    padding: 0 12px;
                    margin-top: 10px;
                    max-width: 100%;
                }
            }
            form {            
                margin-top: 20px;
                margin-bottom: 60px;
                @include mobile-only {
                    margin-bottom: 20px;
                }
                .button {
                    // color: white;
                    background-color:#E2D2B3;
                    @media (max-width: 639px) {
                        width: 100%;
                    }
                    &:hover{
                        background:#fff;
                        color:#000;
                    }
                }
            }
            a {
                color: unset;
            }
        }
    }    
    h1{ 
       font-family: "Playfair Display";
       text-align: center; 
       font-size: 50px;
       margin: 50px 0 10px 0 ;
       color: white;
       height: fit-content;
       position:relative;
       font-weight:600;
       @include tablet-up {
            text-align: start;
            font-size: 60px;
            margin: 0 0 0 0 ;
        }
    }
    .its-time{
        position:absolute;
        @include tablet-down{
            width:145px;
            height:145px;
            max-width:30%;
            top: 5%;
            left: 10%;
        }
        @include tablet-up{
            right:5%;
            margin-bottom:-15px;
        }
        @include desktop-wide-up {
            right:10%;
        }
    }
    h2{
        font-family: "Playfair Display";
        color:white;
        text-align:center;
        font-size: 8.9vw;
        line-height:1;
        position:relative;
        font-weight:600;
        span{
            font-family: 'Montserrat';
            font-weight:300;
            font-size:5vw;
            line-height:1.2;
            @media (min-width: 640px) {
                font-size:34px;
                line-height:42px;
            }
        }
        @include tablet-up {
            text-align:left;
            font-size:51px;
            line-height:52px;
            letter-spacing:0.09px;
        }
    }
    h3{
        text-align: center;
        color: white;
        margin: 10px 0 22px 0;
        font-size:20px;
        line-height: 24px;
        font-family: Montserrat;
        letter-spacing:.6px;
        font-weight:700;
        text-transform:uppercase;
        @include tablet-up {
            text-align: start;
            margin: 0 0 0 0;
            margin: 10px 0 22px 0;
            font-size:24px;
            line-height: 30px;
        }
    }
    h4{
        color: white;
        text-align: center;
     
        @include tablet-up {
            text-align: start;
  
        }
    }

    .cabiLogoContainer{
        display: flex;
        justify-content: center;
  
        @include tablet-up{
            justify-content: start;
        }
    }
    .mobile-bottom-logo{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        @include tablet-up {
            justify-content: start;
        }
    }
    .register-success{
        margin-top:30px;
    }
    .input-box-container{
        margin-bottom:15px;
    }
}