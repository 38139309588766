@import '../../assets/styles/breakpoints';
.logo {
  @include tablet-up{
    position: relative;
    top: 14px;
  }
  svg {
    width: 60px;
    height: 60px;
  }
  &.dark {
    svg {
      fill: black;
    }
  }
  &.light {
      svg {
        fill: white;
      }
    }
  &:hover {
    opacity: 0.8;
  }
}
