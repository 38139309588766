@import '../../assets/styles/breakpoints';

#watch-page {
    width: 100vw;     
    min-height: 100vh;    
    position: relative;
    background: url("../../assets/images/s25/S25-fsb-watch-bg.png") top center/cover no-repeat;
    @include desktop-lg-up {
        background-image: url("../../assets/images/s25/S25-fsb-watch-bg.png");
        background-position-x: right;
    }
    @include desktop-wide-up {
        background-position-x: center;
    } 
    .container {        
        padding: 48px 20px;
        max-width: 2200px;
        margin: 0 auto;
        @include tablet-down {            
            padding: 20px 14px;
        }
        @include tablet-up {
            padding: 60px 32px;
        }
        .page-title {
            display: flex;
            justify-content: space-between;
            align-items: center;     
            max-width: 970px;
            margin-left: auto;
            margin-right: auto;        
            // @include tablet-down {            
            //     flex-direction: column-reverse;
            //     grid-gap: 30px;
            //     justify-content: center;    
            // }
            .title-text-container {
                font-family:'Playfair Display';
                color:#FFF;
                text-align:left;
                font-size: 8.9vw;
                line-height:1;
                position:relative;
                font-weight:600;
                span{
                    font-family: 'Montserrat';
                    font-weight:300;
                    font-size:5vw;
                    line-height:36px;
                    @include mobile-up{
                        font-size:3.5vw;
                    }
                    @include tablet-up {
                        font-size:34px;
                        line-height:42px;
                    }
                }
                @include mobile-up{
                    font-size: 6vw;
                }
                @media (min-width: 768px){
                    text-align:left;
                }
                @include tablet-up {
                    text-align:left;
                    font-size:64px;
                    line-height:62px;
                    letter-spacing:0.09px;
                }
            }
        }
        .video-container {
            max-width: 1330px;
            width: 100%;
            margin: 30px auto 0 auto;        
            @include tablet-down {
                margin: 25px auto 0 auto;    
            }
            .video-frame { 
                width: 100%;                
                aspect-ratio: 16 / 9;
                position: relative;                                    
                outline: 10px white solid;
                line-height:0;
                @include tablet-down {
                    outline: 6px white solid;  
                    aspect-ratio: 9 / 16;
                    max-height:100vh;
                    width:auto;
                    margin:auto;
                }
                .player {
                    width:100%;
                    height: 100%;
                }
            }
        }   
        .socials-container{
            justify-content:center;
        }
        
        .socials {
            margin-top: 35px;
            @include tablet-down {
                margin-top: 27px;
            }
        }
    }       
}